@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Maitree:300,400,500,600,700&subset=latin-ext');

html {
	position: relative;
	min-height: 100%;
}

body {
	min-height: 100%;
	font-family: 'Maitree', serif;

	ul,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
		font-weight: 300;
	}

	p {
		font-size: 15px;
	}

	.section-heading {
		p {
			font-size: 28px;
			line-height: 28px;

			a {
				font-size: 14px;
				color: @color1;
				text-transform: uppercase;
				margin-left: 15px;
				font-weight: 500;
			}
		}
	}

	.section-description {
		p {
			line-height: 25px;

			strong {
				color: @color1;
			}
		}
	}

	.subpage-section-heading {
		background: url(../img/backgrounds/instruments-801271-min.jpg) center;
		background-size: cover;
		padding: 50px 0;

		p {
			color: #fff;
			font-size: 50px;
			line-height: 50px;
			font-weight: 700;
			color: #fff;
			text-shadow: 1px 1px rgba(0, 0, 0, 0.5), 2px 2px rgba(0, 0, 0, 0.45),
				3px 3px rgba(0, 0, 0, 0.4), 4px 4px rgba(0, 0, 0, 0.35), 5px 5px rgba(0, 0, 0, 0.3),
				6px 6px rgba(0, 0, 0, 0.25), 7px 7px rgba(0, 0, 0, 0.2), 8px 8px rgba(0, 0, 0, 0.15),
				9px 9px rgba(0, 0, 0, 0.1), 10px 10px rgba(0, 0, 0, 0.05);
		}
	}

	.btn-default {
		background: @color1;
		font-size: 14px;
		color: #fff;
		padding: 10px 20px;
		border: 1px solid @color1;
		border-radius: 0 8px 0 8px;
		transition: all 0.3s;
		font-family: 'Roboto', sans-serif;
		text-transform: uppercase;
		font-weight: 400;

		span {
			font-size: 12px;
			margin-right: 12px;
		}

		&:hover {
			background: lighten(@color1, 10%);
			border-radius: 8px 0 8px 0;
			color: #fff;
			border-color: lighten(@color1, 10%);
		}
	}

	.btn-blue {
		background: @color2;
		border: 1px solid @color2;

		&:hover {
			background: lighten(@color2, 10%);
			border-color: lighten(@color2, 10%);
		}
	}

	.thumbnail {
		padding: 0;
		border-radius: 0;
		border: none;
		margin-bottom: 0;
		text-decoration: none;
	}

	.m-t-5 {
		margin-top: 5px;
	}

	.m-t-10 {
		margin-top: 10px;
	}

	.m-t-15 {
		margin-top: 15px;
	}

	.m-t-20 {
		margin-top: 20px;
	}

	.m-t-25 {
		margin-top: 25px;
	}

	.m-t-30 {
		margin-top: 30px;
	}

	.m-b-5 {
		margin-bottom: 5px;
	}

	.m-b-10 {
		margin-bottom: 10px;
	}

	.m-b-15 {
		margin-bottom: 15px;
	}

	.m-b-20 {
		margin-bottom: 20px;
	}

	.m-b-25 {
		margin-bottom: 25px;
	}

	.m-b-30 {
		margin-bottom: 30px;
	}

	.p-t-5 {
		padding-top: 5px;
	}

	.p-t-10 {
		padding-top: 10px;
	}

	.p-t-15 {
		padding-top: 15px;
	}

	.p-t-20 {
		padding-top: 20px;
	}

	.p-t-25 {
		padding-top: 25px;
	}

	.p-t-30 {
		padding-top: 30px;
	}

	.p-b-5 {
		padding-bottom: 5px;
	}

	.p-b-10 {
		padding-bottom: 10px;
	}

	.p-b-15 {
		padding-bottom: 15px;
	}

	.p-b-20 {
		padding-bottom: 20px;
	}

	.p-b-25 {
		padding-bottom: 25px;
	}

	.p-b-30 {
		padding-bottom: 30px;
	}
}

div#section-header {
	nav.navbar {
		background: #111;
		border-radius: 0;
		border: none;
		margin-bottom: 0;
		min-height: auto;
		padding: 20px 0;

		.navbar-header {
			.navbar-brand {
				height: auto;
				padding-top: 0;
				padding-bottom: 0;
				position: relative;
				z-index: 222;

				img {
					max-height: 100px;
				}
			}
		}

		.navbar-social {
			text-align: right;

			ul {
				li {
					padding: 0 10px 0 0;

					img {
						max-height: 30px;
					}
				}
			}
		}

		.navbar-collapse {
			height: auto;
			position: relative;
			z-index: 111;

			ul.navbar-nav {
				position: absolute;
				right: 20px;
				bottom: -10px;

				li {
					a {
						color: #fff;
						padding: 10px;
						font-size: 17px;
					}
				}
			}
		}

		.navbar-toggle {
			background: @color1;
			border: none;

			span {
				background-color: #fff;
			}
		}
	}
}

div#section-main-carousel {
	background: url(../img/backgrounds/instruments-801271-min.jpg) center;
	background-size: cover;
	padding: 100px 0;

	.main-carousel-event {
		text-align: center;

		h1 {
			color: #fff;
			font-size: 50px;
			line-height: 50px;
			font-weight: 700;
			color: #fff;
			text-shadow: 1px 1px rgba(0, 0, 0, 0.5), 2px 2px rgba(0, 0, 0, 0.45),
				3px 3px rgba(0, 0, 0, 0.4), 4px 4px rgba(0, 0, 0, 0.35), 5px 5px rgba(0, 0, 0, 0.3),
				6px 6px rgba(0, 0, 0, 0.25), 7px 7px rgba(0, 0, 0, 0.2), 8px 8px rgba(0, 0, 0, 0.15),
				9px 9px rgba(0, 0, 0, 0.1), 10px 10px rgba(0, 0, 0, 0.05);
		}

		h3 {
			color: #fff;
			margin: 20px 0;
			font-weight: 700;
			text-shadow: 1px 1px rgba(0, 0, 0, 0.5), 2px 2px rgba(0, 0, 0, 0.45),
				3px 3px rgba(0, 0, 0, 0.4), 4px 4px rgba(0, 0, 0, 0.35), 5px 5px rgba(0, 0, 0, 0.3),
				6px 6px rgba(0, 0, 0, 0.25), 7px 7px rgba(0, 0, 0, 0.2), 8px 8px rgba(0, 0, 0, 0.15),
				9px 9px rgba(0, 0, 0, 0.1), 10px 10px rgba(0, 0, 0, 0.05);
		}

		ul {
			li {
				background: #111;
				padding: 10px 20px;
				font-size: 40px;
				color: #fff;
				position: relative;

				&.day {
					&:after {
						position: absolute;
						padding: 5px;
						background: @color1;
						content: 'dzień';
						bottom: -15px;
						left: 15%;
						font-size: 10px;
						width: 70%;
					}
				}

				&.month {
					&:after {
						position: absolute;
						padding: 5px;
						background: @color1;
						content: 'miesiąc';
						bottom: -15px;
						left: 15%;
						font-size: 10px;
						width: 70%;
					}
				}

				&.year {
					&:after {
						position: absolute;
						padding: 5px;
						background: @color1;
						content: 'rok';
						bottom: -15px;
						left: 15%;
						font-size: 10px;
						width: 70%;
					}
				}
			}
		}

		.btn {
			font-size: 20px;
			margin-top: 40px;

			span {
				top: 0;
			}
		}
	}

	.main-carousel-calendar {
		padding: 20px;
		// background: #111;
		background: rgba(0, 0, 0, 0.8);
		box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.5);

		ul {
			li {
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 10px;

				a {
					color: #fff;
				}

				&.date {
					color: @color1;
					font-weight: 700;
					border-bottom: none;
					padding-bottom: 0;
					padding-top: 10px;
				}

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}

				&:nth-last-child(2) {
					border-bottom: none;
				}
			}
		}
	}
}

.shadow5 {

	&:before,
	&:after {
		position: absolute;
		content: '';
		box-shadow: 0 10px 25px 20px rgba(0, 0, 0, 0.5);
		top: 40px;
		left: 30px;
		bottom: 50px;
		width: 15%;
		z-index: -1;
		-webkit-transform: rotate(-4deg);
		-moz-transform: rotate(-4deg);
		transform: rotate(-4deg);
		transition: all 0.3s;
	}

	&:after {
		-webkit-transform: rotate(4deg);
		-moz-transform: rotate(4deg);
		transform: rotate(4deg);
		right: 30px;
		left: auto;
	}

	&:hover {

		&:before,
		&:after {
			box-shadow: 0 10px 25px 20px #3873eb;
		}
	}
}

div#section-news {
	padding-bottom: 45px;

	.news-service {
		padding: 30px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 35px;

		.service-type {
			width: 50%;
			position: absolute;
			left: 25%;
			top: -16px;

			p {
				background: @color2;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		.service-image {
			overflow: hidden;
			height: 190px;
			margin: 25px 0;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}

		.service-name {
			height: 50px;

			p {
				text-align: center;
				color: #111;
				margin-top: 10px;
			}
		}

		.service-date {
			width: 80%;
			position: absolute;
			left: 10%;
			bottom: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}
	}

	.news-button {
		margin-left: 25px;
		margin-top: -5px;
	}
}

div#section-counting {
	background: url(../img/backgrounds/trumpet.jpg) center;
	background-size: cover;
	background-attachment: fixed;

	.count-service {
		.service-number {
			height: 100px;
			width: 100px;
			border-radius: 50%;
			background: @color1;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;

			p {
				color: #fff;
				font-size: 24px;
				font-weight: 700;
			}
		}

		.service-name {
			margin-top: 15px;

			p {
				text-align: center;
				color: #fff;
				font-size: 18px;
			}
		}
	}
}

div#section-about {
	background-image: url(../img/pictures/zespol.jpg);
	background-size: 35%;
	background-position: center right;
	background-repeat: no-repeat;

	.section-description {
		p {
			img {
				padding: 0 10px 0px 0;
				float: left;
			}
		}

		blockquote {
			border-left: 2px solid @color1;
			margin: 20px 0;
			font-size: 18px;
			font-weight: 500;
		}

		ul {
			li {
				margin-top: 20px;
			}
		}
	}

	.music-types-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-right: 100px;
		margin-bottom: 20px;

		img {
			opacity: 0.3;
		}
	}

	.gallery-service {
		padding: 30px 10px 10px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 35px;

		.service-type {
			width: 70%;
			position: absolute;
			left: 15%;
			top: -16px;

			p {
				background: @color2;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		.service-image {
			overflow: hidden;
			height: 120px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}
	}
}

div#section-playlist {
	background: #111;
	padding-bottom: 30px;

	.section-heading {
		p {
			color: #fff;
		}
	}

	.owl-controls {
		padding-top: 10px;
	}

	.playlist-service {
		padding: 10px 10px 30px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 35px;
		margin-bottom: 15px;

		.service-name {
			p {
				text-align: center;
				color: #111;
				margin-top: 10px;
				font-size: 16px;
			}
		}

		.service-date {
			width: 60%;
			position: absolute;
			left: 20%;
			bottom: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;

				span {
					margin-right: 10px;
					font-size: 14px;
				}
			}
		}
	}
}

div#zespol-section-content {
	.pavel-samokhin-wrapper {
		background: url(../img/backgrounds/zespol-pavel.jpg) center;
		background-size: cover;
		background-attachment: fixed;

		.content-flexbox {
			display: flex;
			align-items: center;

			img {
				max-height: 200px;
				margin-right: 40px;
			}

			.flexbox-right {
				padding: 20px;
				background: rgba(255, 255, 255, 0.7);
			}
		}
	}

	.member-service {
		padding: 30px 10px 10px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 40px;

		.service-type {
			width: 70%;
			position: absolute;
			left: 15%;
			top: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		.service-image {
			overflow: hidden;
			height: 150px;
			text-align: center;

			img {
				height: 100%;
			}
		}

		.service-name {
			p {
				text-align: center;
				color: #111;
				margin-top: 10px;
			}
		}
	}

	.modal {
		.modal-content {
			border-radius: 0;
			text-align: center;

			img {
				max-width: 260px;
			}

			h5 {
				color: @color1;
				padding: 10px 0;
				font-size: 28px;
				font-weight: 600;
			}
		}
	}

	.descriptions-flexbox {
		display: flex;
		justify-content: space-between;

		a.flexbox-service {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-top: 30px;
			width: 22%;
			padding: 10px;
			background: @color1;
			font-size: 16px;
			text-transform: uppercase;
			line-height: 25px;
			font-weight: 300;
			color: #fff;
			text-decoration: none;
		}
	}

	.heading-important {
		p {
			text-transform: uppercase;
			color: @color1;
			font-size: 22px;
			text-align: center;
			font-weight: 400;
		}
	}

	.discography-flexbox {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.flexbox-service {
			width: 220px;
			margin-top: 15px;
			text-align: center;
			text-decoration: none;
			margin-right: 30px;

			.service-image {
				height: 220px;
				display: flex;
				align-items: center;
				border: 5px solid @gray-lighter;

				img {
					width: 100%;
				}
			}

			p {
				color: @color1;
				font-size: 17px;
				margin-top: 10px;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

div#koncerty-section-content {
	padding-bottom: 20px;

	.news-service {
		padding: 10px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 50px;

		.service-image {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}

		.service-name {
			height: 40px;

			p {
				text-align: center;
				color: #111;
				margin-top: 10px;
			}
		}

		.service-date {
			width: 80%;
			position: absolute;
			left: 10%;
			bottom: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		&:first-child {
			margin-top: 30px;
		}
	}

	.pagination {
		margin-bottom: 0;
		margin-top: 45px;

		li {
			a {
				background: @gray-lighter;
				border: 1px solid @gray-light;
				color: @gray;
			}

			&.active {
				a {
					background: @color1;
					border-color: @color1;
					color: #fff;
				}
			}
		}
	}
}

div#news-section-content {
	padding-bottom: 20px;

	.news-service {
		padding: 10px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 50px;

		.service-image {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}

		.service-name {
			height: 40px;

			p {
				text-align: center;
				color: #111;
				margin-top: 10px;
			}
		}

		.service-date {
			width: 80%;
			position: absolute;
			left: 10%;
			bottom: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		&:first-child {
			margin-top: 30px;
		}
	}

	.pagination {
		margin-bottom: 0;
		margin-top: 45px;

		li {
			a {
				background: @gray-lighter;
				border: 1px solid @gray-light;
				color: @gray;
			}

			&.active {
				a {
					background: @color1;
					border-color: @color1;
					color: #fff;
				}
			}
		}
	}

	.sidebar-news-service {
		padding: 30px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 35px;

		.service-type {
			width: 50%;
			position: absolute;
			left: 25%;
			top: -16px;

			p {
				background: @color2;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		.service-image {
			overflow: hidden;
			height: 190px;
			margin: 10px 0;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}

		.service-date {
			width: 80%;
			position: absolute;
			left: 10%;
			bottom: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}
	}

	.sidebar-margin {
		margin-top: 60px;
	}
}

div#teledyski-section-content {
	padding-bottom: 20px;

	iframe.video-iframe {
		width: 100%;
		height: 200px;
		margin-top: 30px;
		border: none;
	}

	.pagination {
		margin-bottom: 0;
		margin-top: 45px;

		li {
			a {
				background: @gray-lighter;
				border: 1px solid @gray-light;
				color: @gray;
			}

			&.active {
				a {
					background: @color1;
					border-color: @color1;
					color: #fff;
				}
			}
		}
	}

	.sidebar-news-service {
		padding: 30px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 35px;

		.service-type {
			width: 50%;
			position: absolute;
			left: 25%;
			top: -16px;

			p {
				background: @color2;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		.service-image {
			overflow: hidden;
			height: 190px;
			margin: 10px 0;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}

		.service-date {
			width: 80%;
			position: absolute;
			left: 10%;
			bottom: -16px;

			p {
				background: @color1;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}
	}

	.sidebar-margin {
		margin-top: 60px;
	}
}

div#wspolpraca-section-content {
	.description-flexbox {
		display: flex;
		align-items: center;

		.btn {
			margin-left: 50px;
		}
	}

	.gallery-service {
		height: 150px;
		overflow: hidden;
		background: @gray-lighter;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;

		img {
			width: 100%;
		}
	}

	.content-flexbox {
		display: flex;
		align-items: center;
		margin-top: 30px;

		img {
			max-width: 300px;
			margin-right: 50px;
		}

		.flexbox-description {
			.section-description {
				margin-top: 15px;
			}
		}

		&.flexbox-reverse {
			flex-direction: row-reverse;

			img {
				margin-right: 0;
				margin-left: 50px;
			}

			.flexbox-description {
				text-align: right;
			}
		}
	}
}

div#galerie-section-content {
	padding-bottom: 30px;

	.gallery-service {
		padding: 30px 10px 10px 10px;
		background: @gray-lighter;
		position: relative;
		margin-top: 45px;

		.service-type {
			width: 70%;
			position: absolute;
			left: 15%;
			top: -16px;

			p {
				background: @color2;
				color: #fff;
				padding: 5px;
				text-align: center;
			}
		}

		.service-image {
			overflow: hidden;
			height: 120px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
			}
		}
	}
}

div#kontakt-section-content {
	.social-media-flexbox {
		display: flex;
		justify-content: flex-start;
		margin-top: 15px;

		a {
			&:first-child {
				margin-right: 20px;
			}

			img {
				max-height: 50px;
			}
		}
	}

	.contact-info {
		display: flex;
		flex-direction: column;

		a {
			font-size: 20px;
			color: @color1;
			margin-top: 5px;
			text-decoration: none;
		}
	}

	form {
		label {
			color: @color1;
		}

		input,
		textarea {
			border-radius: 0 8px 0 8px;
			border: 1px solid @color1;
			box-shadow: none;
		}

		input {
			height: 40px;
		}
	}
}

div#section-footer {
	background-color: #111;
	position: relative;

	.img-footer {
		position: absolute;
		right: 0;
		bottom: 0;
		max-height: 420px;
	}

	.footer-description {
		.section-heading {
			margin-bottom: 10px;
		}

		a {
			font-weight: 700;
			font-size: 18px;
			color: @color1;
			line-height: 20px;
		}

		.media {
			color: #fff;

			img {
				max-width: 70px;
			}

			h4 {
				padding-top: 10px;
			}
		}

		ul.footer-social {
			li {
				padding: 0 10px 0 0;

				a {
					font-size: 14px;
					font-weight: 300;
					color: @gray-light;
				}

				img {
					max-height: 30px;
					margin-right: 10px;
				}

				&:first-child {
					margin-bottom: 10px;
				}
			}
		}
	}

	.footer-sitemap {
		.section-heading {
			margin-bottom: 10px;
		}

		ul {
			li {
				border-bottom: 1px solid @gray-dark;

				a {
					padding: 10px 0;
					display: inline-block;
					color: #fff;
				}

				&:first-child {
					a {
						padding-top: 0;
					}
				}

				&:last-child {
					border-bottom: none;

					a {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.footer-news {
		.section-heading {
			margin-bottom: 10px;
		}

		ul {
			li {
				border-bottom: 1px solid @gray-dark;

				a {
					padding-bottom: 10px;
					display: inline-block;
					color: #fff;
				}

				&:first-child {
					a {
						padding-top: 0;
					}
				}

				&:last-child {
					border-bottom: none;

					a {
						padding-bottom: 0;
					}
				}

				&.date {
					border-bottom: none;
					padding: 0;
					color: @color1;
					margin-top: 10px;
					font-weight: 700;
				}
			}
		}
	}

	.footer-divider {
		width: 1px;
		height: 240px;
		background: @gray-darker;
		margin: 0 auto;
	}

	.footer-cta {
		img {
			max-width: 170px;
		}

		h3 {
			margin-top: 10px;
		}

		p {
			color: #fff;
			margin-top: 10px;
		}
	}
}

@media (max-width: @screen-md-max) {
	.md-m-t-30 {
		margin-top: 30px;
	}
}

@media (max-width: @screen-sm-max) {
	.sm-m-t-30 {
		margin-top: 30px;
	}

	div#section-about {
		background: none;

		.section-description {
			ul {
				li {
					margin-top: 20px;
				}
			}
		}

		.music-types-flexbox {
			flex-wrap: wrap;
		}
	}

	div#wspolpraca-section-content {
		.description-flexbox {
			flex-direction: column;
			align-items: flex-start;

			.btn {
				margin-left: 0px;
				margin-top: 30px;
			}
		}

		.content-flexbox {
			flex-direction: column;

			img {
				margin-right: 0px;
			}

			.flexbox-description {
				text-align: center;
				margin-top: 10px;
			}

			&.flexbox-reverse {
				flex-direction: column;

				img {
					margin-right: 0;
					margin-left: 0px;
				}

				.flexbox-description {
					text-align: center;
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	.xs-m-t-30 {
		margin-top: 30px;
	}

	div#section-header {
		nav.navbar {
			.navbar-collapse {
				position: relative;
				border: none;
				box-shadow: none;

				ul.navbar-nav {
					position: relative;
					right: auto;
					bottom: auto;
				}
			}
		}
	}

	div#section-main-carousel {
		.main-carousel-event {
			h1 {
				font-size: 35px;
			}

			ul {
				li {
					font-size: 20px;
					display: block;
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	div#section-news {
		.news-service {
			margin-top: 60px;

			.service-image {
				height: auto;
			}
		}
	}

	div#zespol-section-content {
		.pavel-samokhin-wrapper {
			.content-flexbox {
				flex-direction: column;

				img {
					margin-right: 0px;
					max-width: 100%;
				}

				.flexbox-right {
					margin-top: 30px;
				}
			}
		}
	}
}